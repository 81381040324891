<template>
  <div class="Navigator">
    <div class="nav-cont">
      <img :src="pcLogo" alt="logo" class="logo pc" />
      <img :src="moLogo" alt="logo" class="logo mo" />

      <img src="../assets/custom/bars.svg" alt="" class="bars mo" @click="open = true" />
      <div class="menu-cont" :class="{ 'activated': open }">
        <img src="../assets/custom/cross.svg" alt="" class="bars mo" @click="open = false" />
        <a @click="navigation('/')" class="menu-a first active">INICIO</a>
        <a @click="navigation('nosotros')" class="menu-a">NOSOTROS</a>
        <a @click="navigation('academia')" class="menu-a">ACADEMIA</a>
        <a @click="navigation('clase-de-prueba')" class="menu-a">CLASE DE PRUEBA</a>
        <a @click="navigation('contacto')" class="menu-a">CONTACTO</a>
        <!-- <div class="search-cont" v-if="buscador">
          <button><img src="../assets/lupa.svg" alt="" class="lupa" /></button>
          <input type="search" id="site-search" name="q" />
        </div> -->
        <div class="sm-cont">
          <a href="https://www.facebook.com/playtennismx" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/fb-i.svg" alt="sm" class="sm-i" />
          </a>
          <a href="https://www.instagram.com/playtennismx" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/ig-i.svg" alt="sm" class="sm-i" />
          </a>
          <!-- <a href="" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/tw-i.svg" alt="sm" class="sm-i" />
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pcLogo from "../assets/logonav.png";
import moLogo from "../assets/logonav-mo.png";
export default {
  data() {
    return {
      buscador: true /* Activar buscador */,
      moLogo,
      pcLogo,
      open: false,
    };
  },

  methods: {
    navigation(link) {
      if (this.$route.name === 'home' && link !== '/') {
        window.location.href = `#${link}`;
      } else if (link === '/') {
        window.location.href = "/"
      } else {
        this.$router.push("/")

        setTimeout(() => {
          window.location.href = `#${link}`;
        }, 600);
      }
    }
  }
};
</script>
<style scoped>
.pc {
  display: block;
}

.mo {
  display: none;
}

.Navigator {
  width: 100%;
}

.nav-cont {
  width: 88.698vw;
  margin: 0 auto;
  padding: 3.906vw 0 2.344vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Navigator .logo {
  width: auto;
  height: 8.021vw;
}

.menu-cont {
  display: flex;
  align-items: center;
}

/**items menu */
.menu-a {
  font-family: var(--font-titulo2);
  font-size: var(--fs-menupc);
  color: var(--co-menui);
  margin: 0 1.016vw;
  transition: 0.5s;
  cursor: pointer;
}

.menu-a:hover {
  color: var(--co-menui-active);
}

/**buscador */
.search-cont {
  width: 8.542vw;
  height: 1.771vw;
  display: flex;
  align-items: center;
  border: var(--im-border);
  border-radius: 1.563vw;
}

.search-cont button {
  background: none;
  border: none;
  padding: 0;
  width: 1.785vw;
}

.search-cont button img {
  width: 0.885vw;
  background: none;
  border: none;
  margin-left: 0.732vw;
  display: flex;
  filter: var(--lupapc-color);
}

.search-cont input#site-search {
  width: 100%;
  height: auto;
  background: none;
  border: none;
  font-family: var(--font-titulo2);
  font-size: var(--fs-menupc);
  color: var(--co-menui);
}

/**social media cont */
.sm-cont {
  width: 5.417vw;
  height: 1.406vw;
  margin-left: 0.886vw;
  display: flex;
  justify-content: space-between;
}

.sm-cont a {
  display: flex;
}

.sm-cont img {
  width: 1.354vw;
  filter: var(--sm-icon-color);
}

.sm-cont img:hover {
  filter: var(--sm-icon-hover);
}

/**MOBILE STYLES */
@media (max-width: 768px) {
  .pc {
    display: none;
  }

  .mo {
    display: block;
  }

  .bars {
    width: 10.628vw;
    filter: var(--menu-bars);
  }

  .activated {
    display: flex !important;
  }

  .menu-cont {
    display: none;
    position: fixed;
    background: var(--fondo-bk);
    top: 0;
    right: 0;
    width: 54.831vw;
    height: 100%;
    z-index: 100;
    flex-direction: column;
    align-items: flex-end;
    overflow-y: scroll;
    padding: 10.386vw 7.971vw 10.386vw 14.493vw;
  }

  .nav-cont {
    width: 86.715vw;
    margin: 0 auto;
    padding: 4.106vw 0 4.831vw;
  }

  .menu-a {
    font-family: var(--font-parrafo);
    font-size: var(--fs-menumo);
    color: var(--color-menumo);
    font-weight: 700;
    margin: 4.71vw 0;
    transition: 0.5s;
    text-align: right;
  }

  .menu-a.first {
    margin: 13.043vw 0 4.71vw !important;
  }

  .Navigator .logo {
    width: auto;
    height: 16.184vw;
  }

  .search-cont {
    width: 54.831vw;
    height: 16.908vw;
    border-radius: 7.246vw;
    border: var(--im-border-mo);
  }

  .search-cont button {
    width: 15.744vw;
  }

  .search-cont button img {
    width: 8.744vw;
    margin-left: 6.498vw;
    filter: var(--lupamo-color);
  }

  .search-cont input#site-search {
    font-family: var(--font-parrafo);
    font-size: var(--fs-menumo);
    color: var(--color-menumo);
  }

  .sm-cont {
    width: 54.831vw;
    height: 13.527vw;
    margin-top: 7.488vw;
    margin-left: 0.886vw;
    display: flex;
    justify-content: space-between;
  }

  .sm-cont img {
    width: 14.251vw;
  }
}
</style>