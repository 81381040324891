<template>
    <main>
        <Navigator />
        <h1>Error 404</h1>
        <p>No hemos logrado encontrar el contenido que buscas</p> <br>
        <p>Utiliza el siguiente enlace para encontrar <a href="/">el camino de regreso</a>.</p>
    </main>
</template>
<script>
import Navigator from '../components/Navigator.vue';
export default {
    name: 'Error404',
    data() {
        return {
            status: 'error',
            message: ''
        }
    },
    components: {
        Navigator,
    },
}
</script>
<style scoped>
*,
*::before,
*::after {
    box-sizing: border-box;
}

main {
    display: grid;
    grid-auto-rows: max-content;
    min-height: 100vh;
    background-size: cover;

    background-image: url("../assets/error_mov.png");
    background-image: url("../assets/error_mov.webp");
}

h1 {
    color: #55CD00;
    font-family: "Monument-bold";
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;

    font-size: 9.66183574879227VW;
    line-height: 90.5%;
    letter-spacing: 0.2898550724637681VW;
    margin: 14.975845410628018VW auto 7.004830917874397VW;
}

p {
    color: #7B7B7B;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;

    font-size: 4.830917874396135VW;
    line-height: 115.5%;
    margin: 0 4.830917874396135VW;
}

a {
    text-decoration: underline;
    text-underline-offset: 0.966183574879227VW;
}

@media (min-width: 768px) {
    main {
        background-image: url("../assets/error_pc.png");
        background-image: url("../assets/error_pc.webp");
    }

    h1 {
        font-size: 3.802083333333333VW;
        line-height: 90.5%;
        letter-spacing: 0.1140625VW;
        margin: 14.270833333333332VW 0 3.5416666666666665VW 51.66666666666667VW;
    }

    p {
        font-size: 1.0416666666666665VW;
        line-height: 115.5%;
        margin: 0 17.1875VW 0 48.69791666666667VW;
    }

    a {
        text-underline-offset: 0.26041666666666663VW;
    }
}
</style>